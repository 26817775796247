import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-denton-texas.png'
import image0 from "../../images/cities/scale-model-of-discover-denton-welcome-center-in-denton-texas.png"
import image1 from "../../images/cities/scale-model-of-curemed-assist--medical-tourism-company-in-denton-texas.png"
import image2 from "../../images/cities/scale-model-of-denton-convention-&-visitors-bureau-in-denton-texas.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Denton'
            state='Texas'
            image={image}
            lat='33.2166'
            lon='-97.1414'
            attractions={ [{"name": "Discover Denton Welcome Center", "vicinity": "111 W Hickory St, Denton", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 33.214243, "lng": -97.13303100000002}, {"name": "CureMed Assist -Medical Tourism Company", "vicinity": "Texas St, Denton", "types": ["health", "point_of_interest", "establishment"], "lat": 33.2222446, "lng": -97.12368620000001}, {"name": "Denton Convention & Visitors Bureau", "vicinity": "414 W Parkway St, Denton", "types": ["point_of_interest", "local_government_office", "establishment"], "lat": 33.2184945, "lng": -97.1367487}] }
            attractionImages={ {"Discover Denton Welcome Center":image0,"CureMed Assist -Medical Tourism Company":image1,"Denton Convention & Visitors Bureau":image2,} }
       />);
  }
}